.link-with-icon {
  display: inline-flex;
  align-items: center;
  width: auto;
  padding: 2px 8px 2px 0px !important;
  img {
    display: inline;
    margin-top: 2px;
    margin-bottom: 2px;
  }
  p {
    padding-left: 8px;
    font-weight: 500;
    margin-top: 2px;
    margin-bottom: 2px;
    display: inline;
  }
  margin-top: 2px;
  margin-bottom: 2px;
}

.link-with-icon-wrapper {
  display: flex;
  flex-direction: column;
}
