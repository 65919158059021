html {
  font-size: 10px;
  color: black;
  overflow: auto;
  background: white;
  /* font-family: 'Nunito', sans-serif; */
  font-family: "Inter", sans-serif;
}
[data-rmiz-wrap="visible"], [data-rmiz-wrap="hidden"]{
  width: 100%;
  height: 100%;
}

.product-tile__img{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
@media all and (max-width: 1600px) {
  html {
    font-size: 9px;
  }
}

@media all and (max-width: 1200px) {
  html {
    font-size: 9px;
  }
}

@media all and (max-width: 600px) {
  html {
    font-size: 8px;
  }
}

body {
  position: relative;
  overflow: hidden;
}

*,
:before,
:after {
  box-sizing: border-box;
  margin: 0px;
}
