.overlayBox {
  width: 100vw;
  height: calc(100vh - 80px);
  margin-top: 80px;
  position: fixed;
  top: 0;
  left: 100vw;
  z-index: 800;
  @media all and (max-width: 900px) {
    height: calc(100vh - 70px);
    margin-top: 70px;
  }

  &.isActive {
    left: 0vw;
  }

  .overlayBoxOpacity {
    background: #08132d00;
    height: 100%;
    width: 100%;
    transition-duration: 0.3s;
  }

  .isOpacityActive {
    background: #08132d;
  }
}
