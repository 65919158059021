.product-tile-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    padding-bottom: 0px;
  }

  a {
    margin: 32px 0px;
    width: 100%;
    display: block;

    @media all and (max-width: 1200px) {
      margin: 24px 0px;
    }
    @media all and (max-width: 900px) {
      margin: 16px 0px;
    }
  }
  .box {
    border: 1px solid #dfe8fc;
    width: 100%;
    height: 256px;
    padding: 32px;
    &:hover {
      border-color: #4070f4;
    }

    div {
      width: 100%;
      height: 100%;
    }

    .product-tile__img {
      margin-left: auto;
      transition-duration: 0.2s;
      padding-left: 32px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }
  }

  .description {
    padding: 8px;
    padding-bottom: 0px;
    .info-row {
      display: flex;
      font-size: 1.2rem;
      .subtitle {
        color: #b0b0b0;
        width: 100%;
        font-weight: 500;
      }
      .price {
        font-weight: 500;
        color: #4070f4;
        white-space: nowrap;
        text-align: right;
      }
    }

    h5 {
      padding-top: 6px;
      font-weight: 500;
      font-size: 1.6rem;
    }
  }
}
