.product-tabs {
  padding-bottom: 64px;
  margin-top: 32px;
  nav {
    display: inline-flex;
    border: 1px solid #dfe8fc;
    border-bottom: 0px;
    border-right: 0px;
    flex-wrap: wrap;
    @media all and (max-width: 900px) {
      border-right: 1px solid #dfe8fc;
    }
    .tab {
      padding: 12px 32px;
      font-size: 1.6rem;
      // border: 1px solid #dfe8fc;
      border-right: 1px solid #dfe8fc;
      color: #08132d60;
      background: #f5f8fe;
      border-bottom: 3px solid #f5f8fe;
      cursor: pointer;
      font-weight: 500;
      user-select: none;
      transition-duration: 0.2s;
      @media all and (max-width: 900px) {
        padding: 8px 12px;
      }
      &.tab--active {
        color: #08132d;
        background: white;
        border-bottom: 3px solid #4070f4;
      }

      &:hover {
        color: #08132d;
      }
    }
  }
  .tab__body {
    padding: 24px 32px;
    border: 1px solid #dfe8fc;
    min-height: 256px;

    ul {
      padding-top: 4px;
      padding-bottom: 4px;
      li {
        font-size: 1.6rem;
      }
    }

    p {
      font-size: 1.6rem;
    }
  }
}
