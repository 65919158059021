.prefooter-section {
  padding: 0px 0px 128px;
  background: #101c3d;
  padding: 48px 0px;

  @media all and (max-width: 900px) {
    display: none;
  }

  a,
  p,
  h5 {
    color: white;
  }

  .company-col {
    img {
      width: 100%;
      height: auto;
      max-width: 140px;
    }

    h5 {
      font-weight: 700;
      font-size: 1.4rem;
      padding-top: 32px;
      padding-bottom: 16px;
    }

    p {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 170%;
    }
  }

  .links-col {
    @media all and (max-width: 1200px) {
      margin-top: 48px;
    }
    .col-title {
      font-size: 1.2rem;
      font-weight: bold;
      opacity: 0.5;
      padding: 8px 0px;
    }
    ul {
      padding-inline-start: 0px;
      list-style-type: none;
      li {
        padding: 4px 0px;
        font-size: 1.2rem;
        @media all and (max-width: 1600px) {
          padding: 8px 0px;
        }
        @media all and (max-width: 1200px) {
          padding: 12px 0px;
        }
        a {
          &:hover {
            color: #f5f8fe;
            text-decoration: underline;
          }

          &:active {
            color: #4070f4;
          }
        }
      }
    }
  }
}
