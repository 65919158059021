.split-block {
  margin-top: 32px;
  margin-bottom: 32px;
  border: 1px solid #dfe8fc;
  background: white;
  @media all and (max-width: 900px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  @media all and (max-width: 600px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  &.split-block--reversed {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .split-block__image {
    min-height: 400px;
    background-position: center center;
    background-repeat: no-repeat;
    background-position: center;
    @media all and (max-width: 1200px) {
      min-height: 360px;
    }
    @media all and (max-width: 900px) {
      min-height: 320px;
    }
    @media all and (max-width: 600px) {
      min-height: 256px;
    }
  }

  .split-block__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media all and (max-width: 900px) {
      padding-top: 32px !important;
      padding-bottom: 32px !important;
    }
    @media all and (max-width: 600px) {
      padding: 24px !important;
      margin-left: 0px !important;
    }
    h3 {
      font-size: 2rem;
      font-weight: 700;
      color: #08132d;
      padding-bottom: 16px;
      @media all and (max-width: 900px) {
        font-size: 1.8rem;
        padding-bottom: 12px;
      }
    }

    p {
      font-size: 1.5rem;
      line-height: 150%;
      color: #08132d;
      padding-bottom: 16px;
      @media all and (max-width: 900px) {
        font-size: 1.4rem;
        padding-bottom: 12px;
      }
    }

    .split-block__content__action {
      font-weight: 500;
      font-size: 1.5rem;
      display: flex;
      color: #08132d;
      align-items: center;
      padding: 4px;
      margin-left: -4px;
      @media all and (max-width: 900px) {
        font-size: 1.4rem;
      }
      img {
        margin-left: 12px;
        transition-duration: 0.15s;
      }
    }
  }

  &:hover {
    .split-block__content__action {
      img {
        margin-left: 24px;
        transform: scale(1.15);
      }
    }
  }
}
