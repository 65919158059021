.box-link-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;

  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    padding-bottom: 0px;
  }

  a {
    border: 1px solid #dfe8fc;
    padding: 32px 48px;
    font-size: 2rem;
    font-weight: 600;
    color: #08132d;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;

    @media all and (max-width: 1200px) {
      padding: 24px 32px;
      font-size: 1.8rem;
    }
    @media all and (max-width: 900px) {
      padding: 16px 24px;
      font-size: 1.7rem;
    }
    @media all and (max-width: 600px) {
      padding: 16px 20px;
      font-size: 1.6rem;
    }
  }
  .box-logo {
    width: 100%;
    max-width: 100px;
  }
  .icon-arrow {
    margin-left: auto;
    transition-duration: 0.2s;
    padding-left: 32px;
    width: 82px;
    @media all and (max-width: 1200px) {
      width: 64px;
      padding-left: 24px;
    }
    @media all and (max-width: 900px) {
      width: 56px;
      padding-left: 20px;
    }
    @media all and (max-width: 600px) {
      width: 48px;
      padding-left: 16px;
    }
  }

  &:hover {
    a {
      background: #f5f8fe;
    }
    .icon-arrow {
      transform: translateX(12px);
    }
  }
}
