.split-section {
  background: #f5f8fe;
  padding-bottom: 96px;
  @media all and (max-width: 900px) {
    padding-bottom: 64px;
  }
  @media all and (max-width: 600px) {
    padding-bottom: 24px;
  }
}
