.blog-page {
  .blog-news-section {
    padding-bottom: 128px;

    @media all and (max-width: 1200px) {
      padding-bottom: 64px;
    }
    @media all and (max-width: 900px) {
      padding-bottom: 32px;
    }

    .bplusr-nav {
      padding-top: 48px;
      padding-bottom: 32px;
      @media all and (max-width: 1200px) {
        padding-top: 32px;
        padding-bottom: 24px;
      }
      @media all and (max-width: 900px) {
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
  }
}
