.sidenav {
  position: relative;
  h5 {
    font-weight: bold;
    font-size: 1.4rem;
    padding-bottom: 16px;
    position: absolute;
    top: -32px;
    @media all and (max-width: 900px) {
      top: 0px;
      position: initial;
    }
  }
  nav {
    width: 100%;
    border: 1px solid #dfe8fc;
    display: flex;
    flex-direction: column;
    .submenu {
      padding-left: 12px;
      padding-bottom: 4px;
      width: 100%;
      border: 1px solid #dfe8fc;
      display: flex;
      flex-direction: column;
    }
    a {
      width: 100%;
      background: #f5f8fe;
      padding: 12px 16px;
      font-size: 1.4rem;
      color: #101c3d;
      border-bottom: 1px solid #dfe8fc;

      &.is-active {
        background: white;
        border-left: 3px solid #4070f4;
        padding-left: 13px;
      }
    }
  }
}
