.blog-section {
  padding-bottom: 64px;
  @media all and (max-width: 1200px) {
    padding-bottom: 48px;
  }
  @media all and (max-width: 600px) {
    padding-bottom: 32px;
  }
  .blog-section__content {
    padding-top: 64px;
    padding-bottom: 64px;
    @media all and (max-width: 1200px) {
      padding-top: 48px;
      padding-bottom: 48px;
    }
    @media all and (max-width: 600px) {
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}
