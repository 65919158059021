.services-page {
  .services-section {
    padding-top: 128px;
    padding-bottom: 128px;

    @media all and (max-width: 1200px) {
      padding-top: 96px;
      padding-bottom: 96px;
    }
    @media all and (max-width: 900px) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
    @media all and (max-width: 600px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}
