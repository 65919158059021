.partners-section {
  padding-bottom: 128px;
  @media all and (max-width: 1200px) {
    padding-bottom: 96px;
  }
  @media all and (max-width: 900px) {
    padding-bottom: 64px;
  }
  @media all and (max-width: 600px) {
    padding-bottom: 32px;
  }
}

.partners-wrapper {
  padding-top: 16px;
  .partner-block {
    margin-top: 16px;
    margin-bottom: 16px;
    @media all and (max-width: 900px) {
      width: 50% !important;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    text-align: center;
    img {
      padding: 16px 32px;
      width: 100%;
      height: auto;
      filter: grayscale(1);
      opacity: 0.5;
      transition-duration: 0.3s;
      max-width: 256px;
      @media all and (max-width: 900px) {
        padding: 16px 24px;
        max-width: 196px;
      }
      @media all and (max-width: 600px) {
        padding: 8px;
        max-width: 128px;
      }
      &:hover {
        filter: grayscale(0);
        opacity: 1;
      }
    }
  }
}
