footer {
  background: #08132d;

  .creator-col {
    text-align: right;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    padding: 24px 0px;
    letter-spacing: 0.05em;
    
    a {
      color: rgba(255, 255, 255, 0.5);
      padding: 4px;
      color: white;

      &:hover {
        text-decoration: underline;
      }

      &:active {
        color: #4070f4;
      }
    }
  }
}
