
.w-25 {
    width: 25% !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .w-75 {
    width: 75% !important;
  }
  
  .w-100 {
    width: 100% !important;
  }
  
  .w-auto {
    width: auto !important;
  }
  
  .h-25 {
    height: 25% !important;
  }
  
  .h-50 {
    height: 50% !important;
  }
  
  .h-75 {
    height: 75% !important;
  }
  
  .h-100 {
    height: 100% !important;
  }
  
  .h-auto {
    height: auto !important;
  }
  
  .mw-100 {
    max-width: 100% !important;
  }
  
  .mh-100 {
    max-height: 100% !important;
  }
  
  .m-0 {
    margin: 0 !important;
  }
  
  .mt-0,
  .my-0 {
    margin-top: 0 !important;
  }
  
  .mr-0,
  .mx-0 {
    margin-right: 0 !important;
  }
  
  .mb-0,
  .my-0 {
    margin-bottom: 0 !important;
  }
  
  .ml-0,
  .mx-0 {
    margin-left: 0 !important;
  }
  
  .m-1 {
    margin: 4px !important;
  }
  
  .mt-1,
  .my-1 {
    margin-top: 4px !important;
  }
  
  .mr-1,
  .mx-1 {
    margin-right: 4px !important;
  }
  
  .mb-1,
  .my-1 {
    margin-bottom: 4px !important;
  }
  
  .ml-1,
  .mx-1 {
    margin-left: 4px !important;
  }
  
  .m-2 {
    margin: 8px !important;
  }
  
  .mt-2,
  .my-2 {
    margin-top: 8px !important;
  }
  
  .mr-2,
  .mx-2 {
    margin-right: 8px !important;
  }
  
  .mb-2,
  .my-2 {
    margin-bottom: 8px !important;
  }
  
  .ml-2,
  .mx-2 {
    margin-left: 8px !important;
  }
  
  .m-3 {
    margin: 16px !important;
  }
  
  .mt-3,
  .my-3 {
    margin-top: 16px !important;
  }
  
  .mr-3,
  .mx-3 {
    margin-right: 16px !important;
  }
  
  .mb-3,
  .my-3 {
    margin-bottom: 16px !important;
  }
  
  .ml-3,
  .mx-3 {
    margin-left: 16px !important;
  }
  
  .m-4 {
    margin: 32px !important;
  }
  
  .mt-4,
  .my-4 {
    margin-top: 32px !important;
  }
  
  .mr-4,
  .mx-4 {
    margin-right: 32px !important;
  }
  
  .mb-4,
  .my-4 {
    margin-bottom: 32px !important;
  }
  
  .ml-4,
  .mx-4 {
    margin-left: 32px !important;
  }
  
  .m-5 {
    margin: 64px !important;
  }
  
  .mt-5,
  .my-5 {
    margin-top: 64px !important;
  }
  
  .mr-5,
  .mx-5 {
    margin-right: 64px !important;
  }
  
  .mb-5,
  .my-5 {
    margin-bottom: 64px !important;
  }
  
  .ml-5,
  .mx-5 {
    margin-left: 64px !important;
  }
  
  .p-0 {
    padding: 0 !important;
  }
  
  .pt-0,
  .py-0 {
    padding-top: 0 !important;
  }
  
  .pr-0,
  .px-0 {
    padding-right: 0 !important;
  }
  
  .pb-0,
  .py-0 {
    padding-bottom: 0 !important;
  }
  
  .pl-0,
  .px-0 {
    padding-left: 0 !important;
  }
  
  .p-1 {
    padding: 4px !important;
  }
  
  .pt-1,
  .py-1 {
    padding-top: 4px !important;
  }
  
  .pr-1,
  .px-1 {
    padding-right: 4px !important;
  }
  
  .pb-1,
  .py-1 {
    padding-bottom: 4px !important;
  }
  
  .pl-1,
  .px-1 {
    padding-left: 4px !important;
  }
  
  .p-2 {
    padding: 8px !important;
  }
  
  .pt-2,
  .py-2 {
    padding-top: 8px !important;
  }
  
  .pr-2,
  .px-2 {
    padding-right: 8px !important;
  }
  
  .pb-2,
  .py-2 {
    padding-bottom: 8px !important;
  }
  
  .pl-2,
  .px-2 {
    padding-left: 8px !important;
  }
  
  .p-3 {
    padding: 16px !important;
  }
  
  .pt-3,
  .py-3 {
    padding-top: 16px !important;
  }
  
  .pr-3,
  .px-3 {
    padding-right: 16px !important;
  }
  
  .pb-3,
  .py-3 {
    padding-bottom: 16px !important;
  }
  
  .pl-3,
  .px-3 {
    padding-left: 16px !important;
  }
  
  .p-4 {
    padding: 32px !important;
  }
  
  .pt-4,
  .py-4 {
    padding-top: 32px !important;
  }
  
  .pr-4,
  .px-4 {
    padding-right: 32px !important;
  }
  
  .pb-4,
  .py-4 {
    padding-bottom: 32px !important;
  }
  
  .pl-4,
  .px-4 {
    padding-left: 32px !important;
  }
  
  .p-5 {
    padding: 64px !important;
  }
  
  .pt-5,
  .py-5 {
    padding-top: 64px !important;
  }
  
  .pr-5,
  .px-5 {
    padding-right: 64px !important;
  }
  
  .pb-5,
  .py-5 {
    padding-bottom: 64px !important;
  }
  
  .pl-5,
  .px-5 {
    padding-left: 64px !important;
  }
  
  .m-auto {
    margin: auto !important;
  }
  
  .mt-auto,
  .my-auto {
    margin-top: auto !important;
  }
  
  .mr-auto,
  .mx-auto {
    margin-right: auto !important;
  }
  
  .mb-auto,
  .my-auto {
    margin-bottom: auto !important;
  }
  
  .ml-auto,
  .mx-auto {
    margin-left: auto !important;
  }
  
  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important;
    }
  
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important;
    }
  
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important;
    }
  
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important;
    }
  
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important;
    }
  
    .m-sm-1 {
      margin: 4px !important;
    }
  
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 4px !important;
    }
  
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 4px !important;
    }
  
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 4px !important;
    }
  
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 4px !important;
    }
  
    .m-sm-2 {
      margin: 8px !important;
    }
  
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 8px !important;
    }
  
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 8px !important;
    }
  
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 8px !important;
    }
  
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 8px !important;
    }
  
    .m-sm-3 {
      margin: 16px !important;
    }
  
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 16px !important;
    }
  
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 16px !important;
    }
  
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 16px !important;
    }
  
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 16px !important;
    }
  
    .m-sm-4 {
      margin: 32px !important;
    }
  
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 32px !important;
    }
  
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 32px !important;
    }
  
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 32px !important;
    }
  
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 32px !important;
    }
  
    .m-sm-5 {
      margin: 64px !important;
    }
  
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 64px !important;
    }
  
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 64px !important;
    }
  
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 64px !important;
    }
  
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 64px !important;
    }
  
    .p-sm-0 {
      padding: 0 !important;
    }
  
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important;
    }
  
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important;
    }
  
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important;
    }
  
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important;
    }
  
    .p-sm-1 {
      padding: 4px !important;
    }
  
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 4px !important;
    }
  
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 4px !important;
    }
  
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 4px !important;
    }
  
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 4px !important;
    }
  
    .p-sm-2 {
      padding: 8px !important;
    }
  
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 8px !important;
    }
  
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 8px !important;
    }
  
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 8px !important;
    }
  
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 8px !important;
    }
  
    .p-sm-3 {
      padding: 16px !important;
    }
  
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 16px !important;
    }
  
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 16px !important;
    }
  
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 16px !important;
    }
  
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 16px !important;
    }
  
    .p-sm-4 {
      padding: 32px !important;
    }
  
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 32px !important;
    }
  
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 32px !important;
    }
  
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 32px !important;
    }
  
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 32px !important;
    }
  
    .p-sm-5 {
      padding: 64px !important;
    }
  
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 64px !important;
    }
  
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 64px !important;
    }
  
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 64px !important;
    }
  
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 64px !important;
    }
  
    .m-sm-auto {
      margin: auto !important;
    }
  
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important;
    }
  
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important;
    }
  
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important;
    }
  
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important;
    }
  
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important;
    }
  
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important;
    }
  
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important;
    }
  
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important;
    }
  
    .m-md-1 {
      margin: 4px !important;
    }
  
    .mt-md-1,
    .my-md-1 {
      margin-top: 4px !important;
    }
  
    .mr-md-1,
    .mx-md-1 {
      margin-right: 4px !important;
    }
  
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 4px !important;
    }
  
    .ml-md-1,
    .mx-md-1 {
      margin-left: 4px !important;
    }
  
    .m-md-2 {
      margin: 8px !important;
    }
  
    .mt-md-2,
    .my-md-2 {
      margin-top: 8px !important;
    }
  
    .mr-md-2,
    .mx-md-2 {
      margin-right: 8px !important;
    }
  
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 8px !important;
    }
  
    .ml-md-2,
    .mx-md-2 {
      margin-left: 8px !important;
    }
  
    .m-md-3 {
      margin: 16px !important;
    }
  
    .mt-md-3,
    .my-md-3 {
      margin-top: 16px !important;
    }
  
    .mr-md-3,
    .mx-md-3 {
      margin-right: 16px !important;
    }
  
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 16px !important;
    }
  
    .ml-md-3,
    .mx-md-3 {
      margin-left: 16px !important;
    }
  
    .m-md-4 {
      margin: 32px !important;
    }
  
    .mt-md-4,
    .my-md-4 {
      margin-top: 32px !important;
    }
  
    .mr-md-4,
    .mx-md-4 {
      margin-right: 32px !important;
    }
  
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 32px !important;
    }
  
    .ml-md-4,
    .mx-md-4 {
      margin-left: 32px !important;
    }
  
    .m-md-5 {
      margin: 64px !important;
    }
  
    .mt-md-5,
    .my-md-5 {
      margin-top: 64px !important;
    }
  
    .mr-md-5,
    .mx-md-5 {
      margin-right: 64px !important;
    }
  
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 64px !important;
    }
  
    .ml-md-5,
    .mx-md-5 {
      margin-left: 64px !important;
    }
  
    .p-md-0 {
      padding: 0 !important;
    }
  
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important;
    }
  
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important;
    }
  
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important;
    }
  
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important;
    }
  
    .p-md-1 {
      padding: 4px !important;
    }
  
    .pt-md-1,
    .py-md-1 {
      padding-top: 4px !important;
    }
  
    .pr-md-1,
    .px-md-1 {
      padding-right: 4px !important;
    }
  
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 4px !important;
    }
  
    .pl-md-1,
    .px-md-1 {
      padding-left: 4px !important;
    }
  
    .p-md-2 {
      padding: 8px !important;
    }
  
    .pt-md-2,
    .py-md-2 {
      padding-top: 8px !important;
    }
  
    .pr-md-2,
    .px-md-2 {
      padding-right: 8px !important;
    }
  
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 8px !important;
    }
  
    .pl-md-2,
    .px-md-2 {
      padding-left: 8px !important;
    }
  
    .p-md-3 {
      padding: 16px !important;
    }
  
    .pt-md-3,
    .py-md-3 {
      padding-top: 16px !important;
    }
  
    .pr-md-3,
    .px-md-3 {
      padding-right: 16px !important;
    }
  
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 16px !important;
    }
  
    .pl-md-3,
    .px-md-3 {
      padding-left: 16px !important;
    }
  
    .p-md-4 {
      padding: 32px !important;
    }
  
    .pt-md-4,
    .py-md-4 {
      padding-top: 32px !important;
    }
  
    .pr-md-4,
    .px-md-4 {
      padding-right: 32px !important;
    }
  
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 32px !important;
    }
  
    .pl-md-4,
    .px-md-4 {
      padding-left: 32px !important;
    }
  
    .p-md-5 {
      padding: 64px !important;
    }
  
    .pt-md-5,
    .py-md-5 {
      padding-top: 64px !important;
    }
  
    .pr-md-5,
    .px-md-5 {
      padding-right: 64px !important;
    }
  
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 64px !important;
    }
  
    .pl-md-5,
    .px-md-5 {
      padding-left: 64px !important;
    }
  
    .m-md-auto {
      margin: auto !important;
    }
  
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important;
    }
  
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important;
    }
  
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important;
    }
  
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important;
    }
  
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important;
    }
  
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important;
    }
  
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important;
    }
  
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important;
    }
  
    .m-lg-1 {
      margin: 4px !important;
    }
  
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 4px !important;
    }
  
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 4px !important;
    }
  
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 4px !important;
    }
  
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 4px !important;
    }
  
    .m-lg-2 {
      margin: 8px !important;
    }
  
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 8px !important;
    }
  
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 8px !important;
    }
  
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 8px !important;
    }
  
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 8px !important;
    }
  
    .m-lg-3 {
      margin: 16px !important;
    }
  
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 16px !important;
    }
  
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 16px !important;
    }
  
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 16px !important;
    }
  
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 16px !important;
    }
  
    .m-lg-4 {
      margin: 32px !important;
    }
  
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 32px !important;
    }
  
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 32px !important;
    }
  
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 32px !important;
    }
  
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 32px !important;
    }
  
    .m-lg-5 {
      margin: 64px !important;
    }
  
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 64px !important;
    }
  
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 64px !important;
    }
  
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 64px !important;
    }
  
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 64px !important;
    }
  
    .p-lg-0 {
      padding: 0 !important;
    }
  
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important;
    }
  
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important;
    }
  
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important;
    }
  
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important;
    }
  
    .p-lg-1 {
      padding: 4px !important;
    }
  
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 4px !important;
    }
  
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 4px !important;
    }
  
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 4px !important;
    }
  
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 4px !important;
    }
  
    .p-lg-2 {
      padding: 8px !important;
    }
  
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 8px !important;
    }
  
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 8px !important;
    }
  
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 8px !important;
    }
  
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 8px !important;
    }
  
    .p-lg-3 {
      padding: 16px !important;
    }
  
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 16px !important;
    }
  
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 16px !important;
    }
  
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 16px !important;
    }
  
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 16px !important;
    }
  
    .p-lg-4 {
      padding: 32px !important;
    }
  
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 32px !important;
    }
  
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 32px !important;
    }
  
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 32px !important;
    }
  
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 32px !important;
    }
  
    .p-lg-5 {
      padding: 64px !important;
    }
  
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 64px !important;
    }
  
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 64px !important;
    }
  
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 64px !important;
    }
  
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 64px !important;
    }
  
    .m-lg-auto {
      margin: auto !important;
    }
  
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important;
    }
  
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important;
    }
  
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important;
    }
  
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important;
    }
  }
  
  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important;
    }
  
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important;
    }
  
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important;
    }
  
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important;
    }
  
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important;
    }
  
    .m-xl-1 {
      margin: 4px !important;
    }
  
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 4px !important;
    }
  
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 4px !important;
    }
  
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 4px !important;
    }
  
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 4px !important;
    }
  
    .m-xl-2 {
      margin: 8px !important;
    }
  
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 8px !important;
    }
  
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 8px !important;
    }
  
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 8px !important;
    }
  
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 8px !important;
    }
  
    .m-xl-3 {
      margin: 16px !important;
    }
  
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 16px !important;
    }
  
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 16px !important;
    }
  
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 16px !important;
    }
  
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 16px !important;
    }
  
    .m-xl-4 {
      margin: 32px !important;
    }
  
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 32px !important;
    }
  
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 32px !important;
    }
  
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 32px !important;
    }
  
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 32px !important;
    }
  
    .m-xl-5 {
      margin: 64px !important;
    }
  
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 64px !important;
    }
  
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 64px !important;
    }
  
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 64px !important;
    }
  
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 64px !important;
    }
  
    .p-xl-0 {
      padding: 0 !important;
    }
  
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important;
    }
  
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important;
    }
  
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important;
    }
  
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important;
    }
  
    .p-xl-1 {
      padding: 4px !important;
    }
  
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 4px !important;
    }
  
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 4px !important;
    }
  
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 4px !important;
    }
  
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 4px !important;
    }
  
    .p-xl-2 {
      padding: 8px !important;
    }
  
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 8px !important;
    }
  
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 8px !important;
    }
  
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 8px !important;
    }
  
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 8px !important;
    }
  
    .p-xl-3 {
      padding: 16px !important;
    }
  
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 16px !important;
    }
  
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 16px !important;
    }
  
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 16px !important;
    }
  
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 16px !important;
    }
  
    .p-xl-4 {
      padding: 32px !important;
    }
  
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 32px !important;
    }
  
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 32px !important;
    }
  
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 32px !important;
    }
  
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 32px !important;
    }
  
    .p-xl-5 {
      padding: 64px !important;
    }
  
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 64px !important;
    }
  
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 64px !important;
    }
  
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 64px !important;
    }
  
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 64px !important;
    }
  
    .m-xl-auto {
      margin: auto !important;
    }
  
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important;
    }
  
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important;
    }
  
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important;
    }
  
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important;
    }
  }
  