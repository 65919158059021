.cta-section {
  background-color: #4070f4;
  color: #ffffff;
  @media all and (max-width: 900px) {
    padding: 16px 0px;
  }
  .cta__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0px 0px;
    align-items: flex-start;
    @media all and (max-width: 1200px) {
      padding: 32px 64px;
    }
    @media all and (max-width: 900px) {
      padding: 32px;
      align-items: center;
    }

    h3 {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 120%;
      padding-bottom: 24px;
      color: #ffffff;
      @media all and (max-width: 1200px) {
        font-size: 2.2rem;
      }
      @media all and (max-width: 900px) {
        padding-bottom: 16px;
      }
    }

    p {
      color: #ffffff;
      font-size: 1.8rem;
      line-height: 150%;
      padding-bottom: 32px;
      @media all and (max-width: 900px) {
        font-size: 1.6rem;
        padding-bottom: 16px;
        text-align: center;
      }
    }
  }

  .cta__image {
    width: 100%;
    height: 100%;
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    @media all and (max-width: 900px) {
      display: none;
    }
  }
}
