.navbar {
  background: #101c3d;
  height: 80px;
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 1002;
  width: 100%;

  .row {
    align-items: center;
  }

  .main {
    text-align: center;

    button,
    .expandableMenuItem,
    .expandableLink,
    a {
      color: white !important;
      font-size: 1.4rem;
      margin-left: 32px;
      margin-right: 32px;
      font-weight: 500 !important;
      padding: 4px;

      outline: none !important;
      box-shadow: none !important;

      &:hover {
        text-decoration: underline;
      }

      &::after {
        text-decoration: none !important;
      }

      @media all and (max-width: 1600px) {
        margin-left: 24px;
        margin-right: 24px;
      }

      @media all and (max-width: 1200px) {
        margin-left: 16px;
        margin-right: 16px;
      }
      @media all and (max-width: 900px) {
        margin-left: 12px;
        margin-right: 12px;
        background: #101c3d;
        width: 100%;
        margin-bottom: 1px;
      }
    }

    button[class^="Dropdown_button"],
    button[class*="Dropdown_button"] {
      &:hover {
        text-decoration: none !important;
      }

      &::after {
        text-decoration: none !important;
      }
    }

    div[class^="Dropdown_menu"],
    div[class*="Dropdown_menu"] {
      ul {
        li {
          a {
            color: black !important;
          }
        }
      }
    }
    .is-active {
      color: #4070f4;
    }

    .first-multilevel-menu {
      margin-left: 32px;
      right: auto;

      @media all and (max-width: 1600px) {
        margin-left: 24px;
      }

      @media all and (max-width: 1200px) {
        margin-left: 16px;
      }

      li {
        padding: 0px;
      }

      a {
        color: #08132d;
        text-align: left;
        margin-left: 0;
        margin-right: 0;
        width: 100%;
        padding: 8px 16px;

        @media all and (max-width: 1600px) {
          padding: 8px 12px;
        }

        @media all and (max-width: 1200px) {
          padding: 8px 8px;
        }

        font-size: 1.4rem;
        font-weight: 500;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .first-multilevel-submenu {
      right: auto;
    }

    .first-multilevel-button {
      background: none !important;
      outline: none;
      border: none;
      color: white;
      font-size: 1.4rem;
      margin-left: 32px;
      margin-right: 32px;
      font-weight: 500;
      padding: 4px;
      position: relative;
      height: auto;

      &::after {
        content: " \25BE";
        display: block;
        width: 4px;
        height: 4px;
        margin-left: 8px;
        margin-top: -6px;
      }

      @media all and (max-width: 1600px) {
        margin-left: 24px;
        margin-right: 24px;
      }

      @media all and (max-width: 1200px) {
        margin-left: 16px;
        margin-right: 16px;
      }
      @media all and (max-width: 900px) {
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }

  .action-btn {
    text-align: right;
  }

  @media all and (max-width: 900px) {
    height: 70px;
    padding-top: 16px;
    padding-bottom: 16px;
    .hamburder-col {
      width: 50% !important;
    }
    .logo {
      width: 50% !important;

      img {
        height: 32px;
        width: auto;
      }
    }
    .mobile {
      top: 70px;
      opacity: 0;
      transition-duration: 0.3s;
      transition-delay: 0.3s;
      animation-timing-function: cubic-bezier(1, -0.1, 0.9, 0.995);
      transform: translateY(100%);
      left: 0px;
      background: #08132d;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      position: fixed;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      z-index: 1001;

      .main {
        opacity: 0;
        transition-duration: 0.3s;
        animation-timing-function: cubic-bezier(1, -0.1, 0.9, 0.995);
        transform: translateY(100%);
        height: auto;
        min-height: 100%;

        display: flex;
        text-align: center;
        flex-direction: column !important;
        // justify-content: center;
        align-items: center;
        padding-top: 4px;
        padding-bottom: calc(80px + 32px);

        button,
        a,
        .expandableLink,
        .first-multilevel-button {
          color: white !important;
          padding: 16px;
          margin-top: 4px;
          margin-bottom: 4px;
          text-align: left;
          text-decoration: none;
        }

        .expandableMenuItem {
          margin-top: 0px;
          border-top: 4px solid #08132d;
          display: flex;
          flex-direction: column;
          padding-left: 16px;
          text-decoration: none;

          padding-top: 12px;
          padding-bottom: 12px;
          margin-top: 2px;

          .expandableMenuItem {
            padding-top: 0px;
            padding-bottom: 0px;

            &:last-of-type {
              margin-bottom: -12px;
            }
          }

          &:first-of-type {
            padding-top: 12px;
          }

          &:last-of-type {
            margin-bottom: 4px;
            padding-bottom: 12px;
          }

          .topLinkWrapper {
            margin-left: 0px;
            padding-left: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
            padding: 12px;
            width: 100%;
          }
        }

        .expandableLink {
          margin-left: 0px;
          padding: 0px;
          text-decoration: none;
        }
      }

      &.isOpen {
        transform: translateY(0);
        opacity: 1;
        transition-duration: 0.3s;
        transition-delay: 0s;

        .main {
          transform: translateY(0);
          opacity: 1;
          transition-duration: 0.3s;
        }
      }

      .action-btn {
        margin-top: 16px;
        margin-bottom: 32px;
        text-align: center !important;
      }
    }
  }
}

.navbar__menu {
  opacity: 0;
  transition-duration: 0.3s;
  display: none;
}

@media all and (max-width: 900px) {
  .navbar__menu {
    transform: translateX(0);
    opacity: 1;
    display: block;
    align-self: center;
    margin-left: auto;
    cursor: pointer;
    padding: 4px 0px 4px 4px;
    transition-timing-function: ease-in-out;
    filter: brightness(0) invert(1);

    &.isOpen {
      transform: rotate(90deg) translateY(0);
    }
  }
}
