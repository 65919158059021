.title-block {
  padding: 128px 32px 32px 32px;
  width: 100%;
  color: #08132d;
  @media all and (max-width: 1200px) {
    padding: 96px 24px 24px 24px;
  }
  @media all and (max-width: 600px) {
    padding: 64px 16px 16px 16px;
  }
  h2 {
    font-size: 3rem;
    font-weight: 700;
    @media all and (max-width: 1200px) {
      font-size: 2.6rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.4rem;
    }
  }
  h3 {
    font-size: 2rem;
    font-weight: 700;
    @media all and (max-width: 1200px) {
      font-size: 2.6rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.4rem;
    }
  }

  p {
    padding-top: 16px;
    font-weight: 300;
    font-size: 2rem;
    padding-bottom: 16px;
    @media all and (max-width: 1200px) {
      font-size: 1.8rem;
    }
    @media all and (max-width: 900px) {
      padding-bottom: 8px;
      padding-top: 8px;
      font-size: 1.6rem;
    }
  }

  &.title-block--left {
    text-align: left;
  }

  &.title-block--center {
    text-align: center;
  }

  &.title-block--right {
    text-align: right;
  }

  &.title-block--small-padding-top {
    padding-top: 64px;
    @media all and (max-width: 1200px) {
      padding-top: 32px;
    }
    @media all and (max-width: 900px) {
      padding-top: 16px;
    }
  }
}
