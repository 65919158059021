.text-block {
  table {
    border-collapse: collapse;
    width: 100%;
    font-size: 1.4rem;
    margin-top: 16px;
    margin-bottom: 16px;
    tr:nth-child(even) {
      background-color: #f5f8fe;
    }

    th {
      font-weight: 500;
    }
    td,
    th {
      border: 1px solid #dfe8fc;
      text-align: left;
      padding: 8px;
    }
  }

  img {
    width: 100%;
    height: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  ul,
  li,
  p {
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 1.8;
    color: #08132d;
    margin-top: 12px;
    margin-bottom: 12px;
    @media all and (max-width: 1200px) {
      font-size: 1.6rem;
      line-height: 1.7;
    }
    @media all and (max-width: 900px) {
      font-size: 1.5rem;
      line-height: 1.6;
    }
    a {
      color: #4070f4;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-inline-start: 24px;

    li {
      line-height: 1.4;
    }
  }

  h2,
  .title {
    font-size: 2.5rem;
    color: #08132d;
    padding-bottom: 8px;
    @media all and (max-width: 1200px) {
      font-size: 2.4rem;
    }
    @media all and (max-width: 900px) {
      font-size: 2.2rem;
    }
  }

  h4,
  .subtitle {
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 1.6;
    color: #08132d;
    padding-bottom: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    @media all and (max-width: 1200px) {
      font-size: 1.6rem;
      margin-top: 8px;
      margin-bottom: 8px;
      padding-bottom: 4px;
    }
  }

  .back-link {
    padding-top: 64px;
    font-size: 1.8rem;
    font-weight: 500;
    @media all and (max-width: 1200px) {
      padding-top: 48px;
    }
    @media all and (max-width: 900px) {
      padding-top: 32px;
    }
    a {
      color: #4070f4;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
