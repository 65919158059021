.info-tiles-section {
  padding-top: 16px;
  padding-bottom: calc(128px - 16px);
  @media all and (max-width: 1200px) {
    padding-bottom: 48px;
  }
  @media all and (max-width: 900px) {
    padding-bottom: 32px;
  }
}

.info-tile-block {
  border: 1px solid #dfe8fc;
  padding: 32px 16px;
  display: flex;
  height: 100%;

  transition-duration: 0.15s;
  background: white;
  @media all and (max-width: 1200px) {
    padding: 24px 12px;
    margin: 4px;
  }
  @media all and (max-width: 900px) {
    padding: 16px 8px;
    margin: 4px 0px;
  }
  .info-tile__icon {
    padding: 16px;

    @media all and (max-width: 1200px) {
      padding: 12px;
    }
    @media all and (max-width: 900px) {
      padding: 8px;
    }

    img {
      width: 64px;
      height: auto;
      @media all and (max-width: 1200px) {
        width: 58px;
      }
      @media all and (max-width: 900px) {
        width: 52px;
      }
    }
  }

  .info-tile__content {
    padding-left: 16px;
    width: auto;
    @media all and (max-width: 1200px) {
      padding-left: 12px;
    }
    h4 {
      font-weight: 700;
      font-size: 1.5rem;
      color: #08132d;
      padding-bottom: 16px;
      @media all and (max-width: 1200px) {
        font-size: 1.4rem;
        padding-bottom: 12px;
      }
      @media all and (max-width: 900px) {
        font-size: 1.3rem;
        padding-bottom: 8px;
      }
    }

    p {
      font-size: 1.4rem;
      line-height: 170%;
      color: #08132d;
      @media all and (max-width: 1200px) {
        font-size: 1.3rem;
      }
    }
  }
}

a {
  .info-tile-block {
    &:hover {
      background: #fafbff;
    }
  }
}
