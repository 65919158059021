.categories-page {
  .categories-section {
    padding-bottom: 128px;

    @media all and (max-width: 1200px) {
      padding-bottom: 64px;
    }
    @media all and (max-width: 900px) {
      padding-bottom: 32px;
    }

    .categories-nav {
      padding-top: 48px;
      padding-bottom: 32px;
      @media all and (max-width: 1200px) {
        padding-top: 32px;
        padding-bottom: 24px;
      }
      @media all and (max-width: 900px) {
        padding-top: 24px;
        padding-bottom: 24px;
      }
    }
  }

  .products-section {
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 62vh;

    @media all and (max-width: 1200px) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
    @media all and (max-width: 900px) {
      padding-top: 32px;
      padding-bottom: 32px;
    }

    .search-field {
      margin-top: -16px;
      margin-bottom: -16px;
      @media all and (max-width: 900px) {
        margin-top: 32px;
        margin-bottom: 16px;
      }
    }
  }

  .product-section {
    padding-top: 128px;
    padding-bottom: 128px;
    @media all and (max-width: 1200px) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
    @media all and (max-width: 900px) {
      padding-top: 48px;
      padding-bottom: 48px;
    }

    a {
      padding: 8px 8px 8px 0px;
      font-weight: 500;
      font-size: 1.6rem;
      color: #007aff;
      &:hover {
        text-decoration: underline;
      }
    }

    .price {
      width: 100%;
      text-align: center;
      padding: 64px 0px;
      p {
        display: inline-block;
        font-size: 1.6rem;
        background: #f5f8fe;
        padding: 12px 32px;
        font-weight: 500;
        margin-right: 32px;
        color: #101c3d;
        @media all and (max-width: 900px) {
          width: 100%;
        }
      }
      a {
        padding: 12px 32px;
        font-weight: 500;
        font-size: 1.6rem;
        color: #007aff;
      }
    }

    .box {
      border: 1px solid #dfe8fc;
      width: 100%;
      height: 256px;
      padding: 32px;

      .product-tile__img {
        margin-left: auto;
        padding-left: 32px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
    }
  }
}
