.RelatedProducts {
  display: block;
  text-decoration: none;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none !important;
    }
  }
  h5 {
    color: black;
  }
  .product-tile-wrapper .description .info-row .price {
    padding-top: 0px;
    padding-bottom: 0px;
    text-decoration: none;
  }
}
