.contact-section {
  padding: 0px 0px 128px;

  @media all and (max-width: 1200px) {
    padding: 0px 0px 96px;
  }
  @media all and (max-width: 900px) {
    padding: 0px 0px 64px;
  }
  @media all and (max-width: 600px) {
    padding: 0px 0px 32px;
  }

  .contact-wrapper {
    padding-top: 64px;
    @media all and (max-width: 1200px) {
      padding-top: 48px;
    }
    @media all and (max-width: 900px) {
      padding-top: 0px;
    }
    @media all and (max-width: 600px) {
      padding-top: 0px;
    }
    .contact-person {
      @media all and (max-width: 900px) {
        display: none;
      }
      img {
        max-width: 256px;
        width: 100%;
        height: auto;
      }

      p {
        font-weight: 600;
        font-size: 1.2rem;
      }
    }

    h4 {
      padding: 8px 0px 24px 0px;
      font-size: 2rem;
      @media all and (max-width: 1200px) {
        font-size: 1.8rem;
      }
      @media all and (max-width: 900px) {
        font-size: 1.6rem;
        padding: 32px 0px 8px 0px;
      }
    }

    P {
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 1.4rem;
      color: #08132d;
      line-height: 150%;
      @media all and (max-width: 1200px) {
        font-size: 1.3rem;
      }
      @media all and (max-width: 900px) {
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }
  }
}
