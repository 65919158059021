.fullImg-block {
  width: 100%;
  padding-top: 32px;
  padding-bottom: 32px;

  @media all and (max-width: 1200px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media all and (max-width: 900px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media all and (max-width: 600px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  img {
    width: 100%;
    height: auto;
  }

  .bg-img {
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
