.post-block {
  &.post-block--32 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  &.post-block--24 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  &.post-block--16 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &.no-padding-top {
    padding-top: 0px;
  }

  @media all and (max-width: 1200px) {
    &.post-block--32 {
      padding-top: 24px;
      padding-bottom: 24px;
    }
    &.post-block--24 {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &.post-block--16 {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    &.no-padding-top {
      padding-top: 0px;
    }
  }
  @media all and (max-width: 900px) {
    &.post-block--32 {
      padding-top: 16px;
      padding-bottom: 16px;
    }
    &.post-block--24 {
      padding-top: 12px;
      padding-bottom: 12px;
    }
    &.post-block--16 {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    &.no-padding-top {
      padding-top: 0px;
    }
  }
}
