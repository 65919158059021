.container {
  width: 100%;
  padding: 0px 64px;
  display: flex;
  flex-direction: column;
  max-width: calc(1440px + 128px);
  margin: auto;

  @media all and (max-width: 1200px) {
    padding: 0px 32px;
    max-width: calc(1440px + 64px);
  }

  @media all and (max-width: 600px) {
    padding: 0px 16px;
    max-width: calc(1440px + 32px);
  }

  &.isRaw {
    padding: 0px;
  }
}
