.rules-page {
  .no-pb {
    padding-bottom: 0px !important;
  }

  .no-pt {
    .title-block {
      padding-top: 32px !important;
    }
  }

  .ref-link {
    transform: translateY(-128px);
  }
}
